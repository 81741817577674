import { defineStore } from 'pinia'
import listingsQuery from '../apollo/queries/pages/listings.gql'

export const useListingsStore = defineStore('listings', {
  state: () => ({
    data: {}
  }),
  getters: {
    getListings: (state) => {
      return state.data.listing ? state.data.listing.data.attributes : null
    }
  },
  actions: {
    async requestData() {
      if (Object.keys(this.data).length !== 0) return

      const { data } = await useAsyncQuery(listingsQuery) // eslint-disable-line no-undef

      if (data) this.data = data
    }
  }
})
